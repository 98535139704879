import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";

import ReactGA from "react-ga";

let initAnalytics = () => {
  ReactGA.initialize("UA-183728489-1");
};

function App(props) {
  initAnalytics();
  return (
    <Router>
      <TopMenu />
      <main>
        <Switch>
          <Route exact={true} path="/" component={HomePage} />
          <Route path="/contact" component={ContactPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
