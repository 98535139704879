import React from "react";

import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedIn from "@material-ui/icons/LinkedIn";
import AlternateEmail from "@material-ui/icons/AlternateEmail";
import Instagram from "@material-ui/icons/Instagram";

let SocialIcons = ({ fontSize = "2em", colored = true, describe = false }) => {
  let linkStyle = { lineHeight: fontSize };

  let gray = "#657786";

  let iconStyle = { fontSize: fontSize, lineHeight: fontSize };
  let instaStyle = { ...iconStyle, color: colored ? "#e1306c" : gray };
  let twitterStyle = { ...iconStyle, color: colored ? "#1da1f2" : gray };
  let linkedinStyle = { ...iconStyle, color: colored ? "#0e76a8" : gray };
  let emailStyle = { ...iconStyle, color: colored ? "#657786" : gray };

  let spanStyle = {
    lineHeight: fontSize,
    display: describe ? "inline-block" : "none",
  };
  return (
    <div className="social">
      <a
        href="https://twitter.com/asceticode"
        target="_blank"
        rel="noreferrer"
        style={linkStyle}
      >
        <TwitterIcon style={twitterStyle} />
        <span style={spanStyle}>Twitter</span>
      </a>
      <a
        href="https://www.linkedin.com/company/asceticode"
        target="_blank"
        rel="noreferrer"
        style={linkStyle}
      >
        <LinkedIn style={linkedinStyle} />
        <span style={spanStyle}>LinkedIn</span>
      </a>
      <a href="mailto:office@asceticode.com" style={linkStyle}>
        <AlternateEmail style={emailStyle} />
        <span style={spanStyle}>Email</span>
      </a>
      <a
        href="https://www.instagram.com/asceticode/"
        target="_blank"
        rel="noreferrer"
        style={linkStyle}
      >
        <Instagram style={instaStyle} />
        <span style={spanStyle}>Instagram</span>
      </a>
    </div>
  );
};

export default SocialIcons;
