import React from "react";

//https://unsplash.com/photos/JShu6Rh4Pf4?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink

let NotFoundPage = () => {
  return (
    <div className="banner-container">
      <img
        className="banner-image"
        src={process.env.PUBLIC_URL + "/images/404.jpg"}
        alt="https://unsplash.com/photos/JShu6Rh4Pf4?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink"
      ></img>
      <div className="info-text-box-full">
        <h1>Page Not Found</h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
