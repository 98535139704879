import React from "react";
import logo from "./../logo.png";
import { NavLink } from "react-router-dom";

let TopMenu = () => {
  return (
    <nav>
      <div className="ctr">
        <div>
          <NavLink activeClassName="" className="image-link" to="/">
            <img
              src={logo}
              className="app-logo"
              alt="asceticode logo"
              title="asceticode.com"
            />
          </NavLink>
        </div>
        <div style={{ flexGrow: 1, textAlign: "right" }}>
          <NavLink exact to="/">
            Home
          </NavLink>
          <NavLink to="/contact">Contact</NavLink>
        </div>
      </div>
    </nav>
  );
};

export default TopMenu;
