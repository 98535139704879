import React from "react";
import ReactGA from "react-ga";

let HomePage = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
      <div className="banner-container">
        <img
          className="banner-image"
          src={process.env.PUBLIC_URL + "/images/coffee-top-baner.jpg"}
          alt="https://unsplash.com/photos/NsWcRlBT_74?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink"
        ></img>
        <div className="info-text-box">
          <div className="info">
            Software solutions<span className="prompt">_</span>
            <div className="details">Minimalistic. Thought. As you need!</div>
          </div>
        </div>
      </div>
      <div className="quote-box">
        <div className="quote">
          Everyday life is like programming, I&nbsp;guess. If you love something
          you can put beauty into it.
        </div>
        <div className="author">Donald Knuth</div>
      </div>
    </>
  );
};

export default HomePage;
