import React from "react";
import SocialIcons from "../components/SocialIcons";
import ReactGA from "react-ga";

let ContactPage = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <div className="info-frame">
      <div className="info-container">
        <div className="title">Contact us</div>
        <div className="content">
          <SocialIcons fontSize="3em" describe />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
