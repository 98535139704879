import React from "react";
import { NavLink } from "react-router-dom";
import SocialIcons from "./SocialIcons";

let dateRange = () => {
  let year = new Date().getFullYear();
  if (year === 2020) {
    return "2020";
  }
  return "2020 - " + year;
};

let Footer = () => {
  return (
    <footer>
      <SocialIcons colored={false} describe={false} />
      <div className="copyright">
        designed by&nbsp;
        <NavLink activeClassName="" to="/">
          asceticode
        </NavLink>
        &nbsp;{dateRange()}
      </div>
    </footer>
  );
};

export default Footer;
